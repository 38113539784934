import React, { memo, useRef, useEffect } from 'react';
import { Textfit } from 'react-textfit';

// Animation on scroll
import AOS from "aos";
import "aos/dist/aos.css";

import { Box, Button, SvgIcon, IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Text } from '../context/LanguageCtx'

import HomeNews from './HomeNews';

const iconSizeNews = 70;

function HomeNewsWrapperMobile({ showNews, closeNews, toggleShowNews, setCloseOnScroll, performance }) {
    const newsRef = useRef(null)

    useEffect(() => {
        AOS.init({
          duration: 700
        });
    }, []);

    const scrollToNews = () => {
        window.scroll({
            top: newsRef.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        })
        setTimeout(function() { 
            setCloseOnScroll(true)
        }, 1000);
    }

    const handleClickOpenNews = () => {
        setCloseOnScroll(false)
        scrollToNews()
        toggleShowNews(true)
    }

    return (
        <Box ref={newsRef} className="news-wrapper-mobile content-dark pos-rel overflow">
            <HomeNews showNews={showNews} closeNews={() => closeNews()} performance={performance}/>
            <Box className="t-bold middle news-btn t-center">
                <Box className="news-btn-text"><Text tid="category.expected"/></Box>
                <Box className="news-btn-arrow" onClick={() => handleClickOpenNews()}>
                    <IconButton className="t-red no-padd">
                        <SvgIcon style={{ fontSize: iconSizeNews }}>
                            <ArrowDropDownIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>
                <Button className="btn btn-2 news-btn-name t-bold blc center padd-lft-hlf padd-rgt-hlf" 
                    onClick={() => handleClickOpenNews()} 
                    data-aos="zoom-in"
                    data-aos-offset="-60"
                >
                    <Box className="text-fit-wrapper">
                        <Box className="fullSize text-fit">
                            <Textfit max={80}>
                                <Text tid={`${performance.unique}.prefix`}/> <Text tid={`${performance.unique}.name`}/>
                            </Textfit>
                        </Box>
                    </Box>
                    <Box className="news-btn-info">
                        <Box className="t-red"><Text tid="home.newcategory.musical"/></Box>
                        <Box className="news-theaters">
                            {performance.theaters.map((theatre, index) => {
                                return (
                                    <div key={index}>
                                        {theatre.name} | {theatre.location}
                                    </div>
                                )
                            })}
                        </Box>
                        <Box className="more-info padd-btm-hlf"><Text tid="more.info"/></Box>
                    </Box>
                </Button>
            </Box>
        </Box>
    )
}

export default memo(HomeNewsWrapperMobile)
