import React, { memo } from 'react';

import { Box, Button, SvgIcon, IconButton } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { Text } from '../context/LanguageCtx'

import HomeNews from './HomeNews';

const iconSizeNews = 70;

function HomeNewsWrapper({ showNews, closeNews, toggleShowNews, performance }) {
    return (
        <Box className="news-wrapper fullSize">
            <HomeNews showNews={showNews} closeNews={() => closeNews()} performance={performance}/>
            <Box className="news-btn-wrapper pos-abs content">
                <span className="news-vertical-line"></span>
                <Box className="pos-abs t-bold news-btn">
                    <Box className="pos-rel flex-row">
                        <span className="news-btn-text t-white"><Text tid="category.expected"/></span>
                        <Box className="news-btn-arrow" onClick={() => toggleShowNews(true)}>
                            {showNews ? 
                                <IconButton className="t-red no-padd">
                                    <SvgIcon style={{ fontSize: iconSizeNews }}>
                                        <ArrowLeftIcon />
                                    </SvgIcon>
                                </IconButton>
                                :
                                <IconButton className="t-red no-padd">
                                    <SvgIcon style={{ fontSize: iconSizeNews }}>
                                        <ArrowRightIcon />
                                    </SvgIcon>
                                </IconButton>
                            }
                        </Box>
                        <Button className="btn btn-2 news-btn-name t-bold" onClick={() => toggleShowNews(!showNews)}>
                            <Text tid={`${performance.unique}.prefix`}/> <Text tid={`${performance.unique}.name`}/>
                            <Box className="news-btn-info t-red">
                                <Text tid={`${performance.unique}.category-text`}/>
                            </Box>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default memo(HomeNewsWrapper)
