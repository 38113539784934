import React from 'react'
import { Text } from '../context/LanguageCtx';
import { getMinTheatrePremiere } from '../assets/data'
import Image from './Image';

import Box from '@material-ui/core/Box';

const getMinPremiere = (theatres) => {
    const minPremiere = Math.min.apply(Math, theatres.map(
        tmpTheatres => getMinTheatrePremiere(tmpTheatres)
    ))
    return (
        minPremiere !== 0 &&
            <Box className="image-content-theaters">
                <Text tid="category.premiere"/> {minPremiere}
            </Box>
    )
}

function HomePagePerformancesItem({ performance }) {
    return (
        <Box className={`pos-abs performances-item fullSize t-white ${performance.unique}`}>
            <Box className="pos-rel fullSize">
                <Image className="fullSize" alt={performance.unique} filename={`${performance.unique}-2.jpg`} />
                <Box className="overlay pos-abs"></Box>
                <Box className="content image-content pos-abs">
                    <Box className="image-content-category">
                        <Text tid={performance.category}/>
                    </Box>
                    <Box className="image-content-name push-top-hlf push-btm-hlf t-bold">
                        <Box className="prefix"><Text tid={`${performance.unique}.prefix`}/></Box>
                        <Text tid={`${performance.unique}.name`}/>
                    </Box>
                    {performance.is_inspired &&
                        <Box className="push-btm image-content-inspired-by">
                            <Box className="t-bold inspired-by-text"><Text tid="home.inspired-by"/></Box>
                            <Box className="inspired-by-performance t-bold"><Text tid={`${performance.unique}.inspired-by`}/></Box>
                        </Box>
                    }
                    {getMinPremiere(performance.theaters)}
                </Box>
            </Box>
        </Box>
    )
}

export default HomePagePerformancesItem
