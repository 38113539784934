import React, { memo } from 'react';
import { Text } from '../context/LanguageCtx'

import { Box, Hidden, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import SocialIcons from '../components/SocialIcons';
import Image from './Image';

function HomeNews({ showNews, closeNews, performance }) {
    return (
        <Box className={`news fullSize ${showNews ? "active" : ""}`}>
            {/* <Image className="fullSize news-img pos-abs" alt={`News - ${performance.unique}`} filename={`${performance.unique}-0.jpg`} onError={() => {}}/> */}
            <Box className="overlay pos-abs"/>
            <Hidden lgUp>
                <IconButton className="t-white close-btn pos-abs" onClick={closeNews}>
                    <CloseIcon />
                </IconButton>
            </Hidden>
            <Box className="t-white pos-rel news-content-wrapper">
                <Box className="news-content">
                    <Box className="news-category">
                        <Text tid={`${performance.unique}.category-text`}/>
                    </Box>
                    <h3 className="news-header t-bold padd-top-hlf push-btm"><Text tid={`${performance.unique}.prefix`}/> <Text tid={`${performance.unique}.name`}/></h3>
                    <p className="t-bold">
                        <Text tid={`${performance.unique}.desc`}/>
                    </p>
                    <p>
                        <Text tid={`${performance.unique}.link`}/>
                    </p>
                    <p className="t-bold">
                        Stay tuned
                    </p>
                </Box>
                <Hidden mdDown>
                    <Box className="pos-abs news-follow-us-wrapper t-red fullHeight">
                        <Box className="news-follow-us t-bold t-white">
                            <Text tid="home.followus"/>
                        </Box>
                        <span className="news-vertical-line push-top-hlf push-btm-hlf"></span>
                        <SocialIcons />
                    </Box>
                </Hidden>
            </Box>
        </Box>
    )
}

export default memo(HomeNews)
