import React, { useState, useRef, useEffect } from 'react'
import Image from './Image';

import { Box, Hidden, IconButton } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import HomePagePerformancesItem from './HomePagePerformancesItem';

function PrevArrow({ onClick }) {
    const handleOnClick = () => {
        onClick()
    }
    return (
        <IconButton className="btn btn-3 no-padd slick-arrow slick-prev-arrow" onClick={handleOnClick}>
            <KeyboardArrowLeftIcon/>
        </IconButton>
    );
}

function NextArrow({ onClick }) {
    const handleOnClick = () => {
        onClick()
    }
    return (
        <IconButton className="btn btn-3 no-padd slick-arrow slick-next-arrow" onClick={handleOnClick}>
            <KeyboardArrowRightIcon/>
        </IconButton>
    );
}

function HomePagePerformances({ performances, handleSelectPerformance }) {
    const [performanceInFrame, setPerformanceInFrame] = useState(performances[0]);
    const [nav, setNav] = useState(null);
    const [navThumbnail, setNavThumbnail] = useState(null);
    const slickerRef = useRef(null);
    const slickerRefThumbnail = useRef(null);

    useEffect(() => {
        setNav(slickerRef.current);
        setNavThumbnail(slickerRefThumbnail.current);
    }, []);
    
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        swipeToSlide: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: navThumbnail,
        ref: slickerRef,
    };
    
    const settingsThumbnail = {
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4500,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnHover: false,
        asNavFor: nav,
        ref: slickerRefThumbnail,
        swipeToSlide: false,
        focusOnSelect: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => {
            setPerformanceInFrame(performances[next])
        },
    };

    return (
        <Box className="performances-wrapper fullSize pos-rel">
            <Box className="slider-performance">
                <Slider {...settings}>
                    {performances.map((performance) => {
                        return (           
                            <HomePagePerformancesItem key={performance.unique} performance={performance}/>
                        )
                    })}
                </Slider>
            </Box>
            <Box className="slider-performances pos-abs">
                <Box id="performance-item-frame" className="hand pos-abs btn no-padd" onClick={() => handleSelectPerformance(performanceInFrame)}></Box>
                <Slider {...settingsThumbnail}>
                    {performances.map((performance) => {
                        return (
                            <Box key={performance.unique} className="slicker-item pos-rel center hand" onClick={() => handleSelectPerformance(performance)}>
                                <Image className="fullSize" alt={`${performance.unique} - poster`} filename={`${performance.unique}-poster.jpg`} />
                            </Box>
                        )
                    })}
                </Slider>
            </Box>
        </Box>
    )
}

export default HomePagePerformances
