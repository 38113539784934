import React, { memo, useRef, useEffect, useState, useContext, useCallback } from 'react';
import { Link, navigate } from "gatsby"
import { PerformancesCtx } from "../context/PerformancesCtx";

import { BALLET_OPERA_CATEGORY, MUSICALS_CATEGORY, THEATRE_DANCE_CATEGORY, UPCOMING } from '../assets/data';

import { Box, Hidden } from '@material-ui/core';

import { Text, LanguageCtx } from '../context/LanguageCtx'

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import HomePagePerformances from "../components/HomePagePerformances"

import PlayButtonImg from '../assets/icons/video-play.svg';
import StopButtonImg from '../assets/icons/video-stop.svg';
import BallerinaVideo from '../assets/videos/introVideo.mp4'

import '../assets/css/home.scss'
import HomeNewsWrapper from '../components/HomeNewsWrapper';
import HomeNewsWrapperMobile from '../components/HomeNewsWrapperMobile';

const getPerformanceForHomePage = (getPerformances) => {
    let tmpShow = getPerformances(BALLET_OPERA_CATEGORY).filter((performance) => {
        if(performance.unique === 'carmen' ||
            performance.unique === 'amadeus' ||
            performance.unique === 'chorea') {
            return performance 
        }
        return null
    })
    let tmpMusicals = getPerformances(MUSICALS_CATEGORY).filter((performance) => {
        if(performance.unique === 'confession' || 
            performance.unique === 'water-and-blood' ||
            performance.unique === 'jesus-christ-superstar' || 
            performance.unique === 'west-side-story') {
            return performance 
        }
        return null
    })
    let tmpTheatreDance = getPerformances(THEATRE_DANCE_CATEGORY).filter((performance) => {
        if(performance.unique === 'romeo+juliet') {
            return performance 
        }
        return null
    })
    return tmpShow.concat(tmpMusicals, tmpTheatreDance)
}

const getPerformanceForNews = (getPerformances) => {
    let newsPerformance = getPerformances().find((performance) => {
        if(performance.unique === UPCOMING) {
            return performance
        }
        return null
    })
    return newsPerformance;
}

function HomePage() {
    const { getText } = useContext(LanguageCtx);
    const { getPerformances } = useContext(PerformancesCtx);
    const performances = getPerformanceForHomePage(getPerformances)
    const newsPerformance = getPerformanceForNews(getPerformances)
    const [videoHover, setVideoHover] = useState(false);
    const [videoIsPlayed, setVideoIsPlayed] = useState(false);
    const [showNews, setShowNews] = useState(false);
    const [closeOnScroll, setCloseOnScroll] = useState(true)
    const vidRef = useRef(null);

    useEffect(() => {
        if(closeOnScroll) {
            window.addEventListener('scroll', closeNews)
            return function cleanupListener() {
                window.removeEventListener('scroll', closeNews) // remove listener when leaving from the page
            }
        }
    }, [closeOnScroll])

    const handleSelectPerformance = useCallback((performance) => {
        if (performance.unique === UPCOMING) {
            return;
        }
        navigate(`/production/category/?id=${encodeURIComponent(performance.unique)}`)
    }, [])

    const toggleShowNews = (toggleShow) => {
        if(toggleShow) {
            setShowNews((showNews) => !showNews); 
        }
    }

    const closeNews = () => {
        setShowNews(false)
    }

    const toggleVideoHover = () => {
        setVideoHover((videoHover) => !videoHover);
    }

    const toggleVideoPlay = () => {
        if (videoIsPlayed) {
            setVideoIsPlayed(false);
        } else {
            setVideoIsPlayed(true);
        }
    }

    return (
        <Layout headerType={-1}>
            <SEO title={getText("home")} />
            <Box id="home-page" className='page content-light padd-btm-dbl'>
                <Box className="fullScreen intro-section-wrapper overflow pos-rel">
                    <HomePagePerformances performances={performances} handleSelectPerformance={handleSelectPerformance}/>
                    {newsPerformance &&
                        <Hidden mdDown>
                            <HomeNewsWrapper showNews={showNews} closeNews={closeNews} toggleShowNews={toggleShowNews} performance={newsPerformance}/>
                        </Hidden>
                    }
                </Box>
                {newsPerformance &&
                    <Hidden lgUp>
                        <HomeNewsWrapperMobile setCloseOnScroll={setCloseOnScroll} showNews={showNews} closeNews={closeNews} toggleShowNews={toggleShowNews} performance={newsPerformance}/>
                    </Hidden>
                }
                <Box className="content-light">
                    <Box className="page-content padd-top padd-btm">
                        <h4 className="page-title padd-top-dbl"><Text tid="aboutUs"/></h4>
                        <p className="page-title-text push-btm push-top padd-hlf">
                            <Text tid="aboutUs.preText" />
                        </p>
                        <Box className='t-center padd-btm-dbl push-btm push-top-dbl'>
                            <Link className='btn btn-1' to='/about-us'><Text tid="home.enter"/></Link>
                        </Box>
                        <Box className="video-container padd-hlf push-btm-dbl hand" onMouseEnter={toggleVideoHover} onMouseLeave={toggleVideoHover}>
                            <Box className="pos-rel">
                                {!videoIsPlayed && 
                                    <>
                                        <Box className='overlay pos-abs'/>
                                        <Hidden mdDown>
                                            <img className="middle button-icon play-button" alt="Play video button" src={PlayButtonImg} />
                                        </Hidden>
                                    </>
                                }
                                <Hidden mdDown>
                                    {videoHover && videoIsPlayed && <img className="middle button-icon stop-button" alt="Stop video button" src={StopButtonImg} />}
                                </Hidden>
                                <video className="fullSize" controls ref={vidRef} onPlay={() => toggleVideoPlay()} onPause={() => toggleVideoPlay()}>
                                    <source src={BallerinaVideo} type="video/mp4" />
                                </video>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
}

export default memo(HomePage);